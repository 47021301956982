import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/app.scss';

import { StoreProvider } from "./store/useStore";
import App from './App';
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_SENTRY === 'prod') {
    Sentry.init({
        dsn: "https://46c6f18c6ea19a3a447517efaad8c38d@o4508012722847744.ingest.de.sentry.io/4508019353583696",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost"],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
} else if (process.env.REACT_APP_SENTRY === 'stage') {
    Sentry.init({
        dsn: "https://8a6e3c274d49420d48829d81d3ef26a9@o4508012722847744.ingest.de.sentry.io/4508053834563664",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost"],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

function Main() {
    return (
        <StoreProvider>
            <App />
        </StoreProvider>
    );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Main />, rootElement);
