const apiHost = process.env.REACT_APP_API_HOST;

const config = {
    apiHost,
    apiUrl: `${apiHost}/api`,
    frontendUrl: process.env.REACT_APP_FRONTEND_URL,
    pageAfterLogin: '',
    axiosConfig: function (token, config = {}) {
        return {
            ...config,
            withCredentials: true,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${token}`
            }
        }
    },
    moduleDragItem: 'moduleElement',
    assessmentModuleDragItem: 'assessmentModuleDragItem',
    assessmentTemplateDragItem: 'assessmentTemplateDragItem',
    dimensionSortingConstructDragItem: 'dimensionSortingConstructDragItem',
    dimensionSortingDimensionDragItem: 'dimensionSortingDimensionDragItem',
    defaultLocale: 'en',
    pinResults: {
        threeSixty: 'threeSixty',
        normal: '',
    },
    uploadFileSizeMb: 100,
}

export default config;